import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const PricingPage = ({ data }) => (
  <Layout>
    <SEO title="Pricing" />
    <div className="container page pt-16 pb-20 lg:max-w-lg body-content">
      <h1 className="hero-title mb-8 md:mb-12 lg:text-center">Pricing</h1>
      <div className="mb-16">
        <h2 className="section-header">Lettering Blank Jerseys</h2>
        <p>
          Pricing is based on the number of elements (front number, back number,
          name on back) and the number of colors for each element, like adding a
          separate sewn-on nameplate, and sewing patches.
        </p>
        <p>
          I can provide you an estimate before you send your jersey, but these
          examples should help you gain a general idea of the range of prices
          for a typical project (all prices plus shipping).
        </p>
        <ul className="mb-8">
          <li>
            One-color numbers on back, no player name: <b>$40</b> (just like any
            year Yankees Home jersey)
          </li>
          <li>
            Two-color numbers on back only, two-color player name, direct sewn:{' '}
            <b>$105</b> (like a 2015 Cleveland Indians jersey, for example)
          </li>
          <li>
            Two-color numbers on back, front or sleeve and two-color name on
            back: <b>$140</b>  (Many teams, Like any Phillies home jersey, 1992
            to present)
          </li>
          <li>
            Two-color numbers on front and back, two-color name on a nameplate: 
            <b>$165</b> (Like the Cardinals 2001 jerseys)
          </li>
          <li>
            Crazy ones – four-color numbers front and back, four-color direct
            sewn name on back: <b>$180</b> (Like the Marlins 2010 jerseys)
          </li>
          <li>
            If your team uses nameplates (white, gray, navy, red): <b>~$25</b>
          </li>
          <li>
            Sewing most patches: <b>$10</b> each
          </li>
        </ul>
        <p>
          Send me an email (
          <a href="mailto:bill@thedream.shop">bill@thedream.shop</a>) and I will
          give you a specific quote and an ordering packet that tells you what
          to do next.
        </p>
        <p>Please use this only as a guideline, as prices change over time.</p>
      </div>
      <div>
        <h2 className="section-header">Re-Lettering Old Jerseys</h2>
        <p>
          Estimate that the removal of old lettering usually costs about one
          half the cost of sewing new lettering, though depending on
          color-staining or glue that must be removed, that cost can increase.
        </p>
        <p>
          Removal of old lettering is more time consuming than anything else,
          and to save money, many people will remove their old lettering
          themselves, or at least rip the old stitching before sending to me.
        </p>
        <p>
          A link to a document showing how to remove your old lettering is{' '}
          <a href={data.allFile.edges[0].node.publicURL}>here</a>.
        </p>
      </div>
    </div>
  </Layout>
);

export default PricingPage;

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "Removing jersey lettering" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;
